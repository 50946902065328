import React, {
  ChangeEventHandler,
  Component,
  createRef,
  FormEvent,
  Fragment,
} from 'react';
import classNames from 'classnames';
import { RoleId } from '@wix/members-domain-ts';
import { BadgeList } from '@wix/members-badge-lib';
import { HttpClient } from '@wix/yoshi-flow-editor';
import { IconButton, ImageResizeOptions, WowImage } from 'wix-ui-tpa';
import { CameraFill, Close } from '@wix/wix-ui-icons-common/on-stage';

import {
  badgesAlignmentMap,
  BaseComponentProps,
  DataHook,
  ElementId,
  Position,
  ProfileAlignment,
  ProfileImageSize,
  ProfileLayout,
  ProfileWidgetHeight,
} from '../../../../../types';
import { titleMaxLength } from '../../../../../constants/limits';
import {
  absorbArguments,
  forwardCoverRepositionAttributes,
  forwardResizedImage,
  forwardTargetValue,
} from '../../../../../services/event-handler';
import { getElementId } from '../../../../../services/html-attributes';
import { maybeOpenContactsWindow } from '../../../../../services/navigation';
import LoaderOverlay from '../../../../../common/components/LoaderOverlay';
import Button from '../../../../../common/components/Button';
import { RepositionButton } from '../../../../../common/components/RepositionButton';
import AvatarInput from '../../../../../common/components/AvatarInput';
import MemberName from '../../../../../common/components/MemberName';
import LimitCounter from '../../../../../common/components/LimitCounter';
import RoleIcons from '../../../../../common/components/RoleIcons';
import Blocked from '../../../../../common/components/Blocked';
import FollowButton from '../../../../../common/components/FollowButton';
import MemberMoreButton from '../../../../../common/components/MemberMoreButton';
import styles from './DesktopResponsiveWidget.scss';
import PrivateMemberIndicator from '../../../../../common/components/PrivateMemberIndicator';
import { acceptableImageExtensions } from '../../../../../constants/image';

type SubmitButtonClickHandler = (event: FormEvent<HTMLElement>) => void;

const WIDE_WIDGET_BREAKPOINT = 1001;

interface RenderButtonProps {
  dataHook?: DataHook;
  className?: string;
  title: string;
  isSecondary?: boolean;
  isDisabled?: boolean;
  onClick: SubmitButtonClickHandler;
}

const defaultRenderButtonProps: Partial<RenderButtonProps> = {
  className: '',
  isSecondary: false,
  isDisabled: false,
};

const DEFAULT_FOCAL_POINT = 50;

class DesktopResponsiveWidget extends Component<BaseComponentProps> {
  private coverRef = createRef<HTMLDivElement>();
  private memberNameInputRef = createRef<HTMLInputElement>();

  state = { isMovingCover: false };

  componentDidUpdate({ profilePage }: BaseComponentProps) {
    const { isEditing } = this.props.profilePage;
    const memberNameInput = this.memberNameInputRef.current;

    if (profilePage.isEditing !== isEditing && memberNameInput) {
      memberNameInput.focus();
    }
  }

  render = () => {
    const { site, profilePage, globalSettings, computed, host, isRTL, member } =
      this.props;
    const {
      badgesSettings,
      inCommunity,
      showAsBlocked,
      showAsBlockedByMember,
      showTitle,
      profileAlignment,
      profileWidgetHeight,
      showPrivateMemberIndicator,
    } = computed;
    const {
      isProfileWidgetSmall,
      isProfileWidgetMedium,
      isProfileWidgetLarge,
    } = this.getWidgetHeight(profileWidgetHeight);

    const isRightAlignment =
      profileAlignment === ProfileAlignment.Right && !isRTL;
    const isCenterAlignment = profileAlignment === ProfileAlignment.Center;

    const commonWrapperClasses = {
      [styles.box]: true,
      [styles.smallProfileWidget]: isProfileWidgetSmall,
      [styles.mediumProfileWidget]: isProfileWidgetMedium,
      [styles.largeProfileWidget]: isProfileWidgetLarge,
      [styles.isCenterAlignment]: isCenterAlignment,
      [styles.isRightAlignment]: isRightAlignment,
    };

    if (showAsBlockedByMember) {
      return this.renderEmptyWidget(commonWrapperClasses);
    }

    const { isEditing, isSaving, isCoverRepositionMode, editTitle } =
      profilePage;
    const { showFollowers } = globalSettings;
    const { isSocial } = site;
    const hasTitle = !!(editTitle ?? member.title) && showTitle;
    const isFollowersVisible = isSocial && showFollowers && inCommunity;
    const isOnlyNameVisible =
      !badgesSettings.badges.length && !hasTitle && !isFollowersVisible;

    const containerClasses = {
      [styles.profile]: true,
      [styles.editMode]: isEditing,
      [styles.nonSocial]: !isSocial,
      [this.getWidgetSizeClass(host?.dimensions)]: true,
    };

    const wrapperClasses = {
      ...commonWrapperClasses,
      [styles.noTitle]: !hasTitle,
      [styles.isOnlyNameVisible]: isOnlyNameVisible,
    };

    return (
      <div
        data-hook={DataHook.HorizontalWidget}
        className={classNames(wrapperClasses)}
      >
        {showPrivateMemberIndicator && (
          <PrivateMemberIndicator
            alignRight={true}
            alignBottom={isCenterAlignment}
          />
        )}
        <div className={styles.border} />
        <div className={classNames(containerClasses)}>
          {isSaving && this.renderLoader()}
          {this.renderCover()}
          {!isCoverRepositionMode && (
            <div>
              {isCenterAlignment && this.renderButtons()}
              <div className={styles.layout}>
                <div className={styles.content}>
                  <div className={styles.detailsAndPhoto}>
                    {this.renderPhoto()}
                    <div className={styles.memberDetails}>
                      {this.renderName()}
                      {isFollowersVisible && this.renderFollowers()}
                      {showTitle && this.renderTitle()}
                      {isSocial && showAsBlocked && this.renderBlocked()}
                      {badgesSettings.badges.length > 0 && this.renderBadges()}
                    </div>
                  </div>
                  {!isCenterAlignment && this.renderButtons()}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  private readonly renderEmptyWidget = (wrapperClasses: {
    [p: string]: boolean;
  }) => {
    const { host } = this.props;

    const containerClasses = {
      [styles.profile]: true,
      [styles.editMode]: false,
      [styles.nonSocial]: true,
      [this.getWidgetSizeClass(host?.dimensions)]: true,
    };
    return (
      <div
        data-hook={DataHook.HorizontalWidget}
        className={classNames(wrapperClasses)}
      >
        <div className={styles.border} />

        <div className={classNames(containerClasses)}>
          {this.renderCover()}
          <div className={styles.layout}>
            {this.renderPhoto()}
            <div className={styles.content}>
              <div className={styles.memberDetails} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  private readonly getWidgetHeight = (
    profileWidgetHeight: ProfileWidgetHeight,
  ) => {
    const isProfileWidgetSmall =
      profileWidgetHeight === ProfileWidgetHeight.Small;
    const isProfileWidgetMedium =
      profileWidgetHeight === ProfileWidgetHeight.Medium;
    const isProfileWidgetLarge =
      profileWidgetHeight === ProfileWidgetHeight.Large;

    return {
      isProfileWidgetSmall,
      isProfileWidgetMedium,
      isProfileWidgetLarge,
    };
  };

  private readonly getWidgetSizeClass = (dimensions?: {
    width: number;
    height: number;
  }) => {
    if (!dimensions?.width) {
      return styles.fullWidth;
    }

    if (dimensions.width >= WIDE_WIDGET_BREAKPOINT) {
      return styles.large;
    }

    return '';
  };

  private readonly handleCoverPositionChange = (event: MouseEvent) => {
    const { host, handlers } = this.props;
    const { changeCoverPosition } = handlers;
    const coverWidth = host?.dimensions?.width ?? 0;

    forwardCoverRepositionAttributes(changeCoverPosition, coverWidth)(event);
  };

  private readonly startRepositioningCover = () => {
    const { profilePage } = this.props;
    const { isCoverRepositionMode } = profilePage;

    if (isCoverRepositionMode && this.coverRef.current) {
      this.coverRef.current.addEventListener(
        'mousemove',
        this.handleCoverPositionChange,
      );
      this.setState({ isMovingCover: true });
    }
  };

  private readonly stopRepositioningCover = () => {
    const { profilePage } = this.props;
    const { isCoverRepositionMode } = profilePage;

    if (isCoverRepositionMode && this.coverRef.current) {
      this.coverRef.current.removeEventListener(
        'mousemove',
        this.handleCoverPositionChange,
      );
      this.setState({ isMovingCover: false });
    }
  };

  private readonly renderCoverRepositionButtons = () => {
    const { profilePage, t, handlers } = this.props;
    const { isMovingCover } = this.state;
    const { isCoverRepositionMode } = profilePage;
    const { leaveCoverRepositionMode, saveCoverReposition } = handlers;

    const handleCoverRepositionCancel = absorbArguments(
      leaveCoverRepositionMode,
    );

    const handleCoverRepositionSave = absorbArguments(saveCoverReposition);

    return (
      <>
        {isCoverRepositionMode && !isMovingCover && (
          <div className={styles.repositionModeButtons}>
            <div className={styles.buttonsBox}>
              <IconButton
                icon={<Close />}
                onClick={handleCoverRepositionCancel}
                data-hook={DataHook.CardCoverCancel}
                className={styles.cancelButton}
              />
              {this.renderButton({
                className: styles.truncateText,
                dataHook: DataHook.CardCoverSave,
                title: t('profile-widget.done'),
                isSecondary: true,
                onClick: handleCoverRepositionSave,
              })}
            </div>
          </div>
        )}
      </>
    );
  };

  private readonly renderLoader = () => (
    <LoaderOverlay dataHook={DataHook.Loader} className={styles.loader} />
  );

  private readonly renderCover = () => {
    const { profilePage, t, computed } = this.props;
    const { isMovingCover } = this.state;
    const { showCoverPhoto } = computed;
    const { isCoverLoading, isCoverRepositionMode } = profilePage;

    return (
      <div
        data-hook={DataHook.CoverWrapper}
        className={classNames(styles.coverWrapper, styles.coverColor)}
      >
        {this.renderCoverRepositionButtons()}
        <div
          ref={this.coverRef}
          className={styles.coverBorderMargin}
          onMouseDown={this.startRepositioningCover}
          onMouseUp={this.stopRepositioningCover}
          onMouseLeave={this.stopRepositioningCover}
        >
          {this.renderCoverImage()}
          {isCoverLoading && this.renderLoader()}
          {isCoverRepositionMode && !isCoverLoading && (
            <RepositionButton
              dataHook={DataHook.DragToChangeCoverPositionCTA}
              className={classNames(
                styles.repositionCoverButton,
                isMovingCover && styles.isMoving,
              )}
              text={t('profile-widget.drag-to-reposition')}
            />
          )}
        </div>
        {showCoverPhoto && this.renderCoverButtons()}
      </div>
    );
  };

  private readonly renderPhoto = () => {
    const { member, profilePage, computed, handlers } = this.props;
    const { isProfilePreview, isEditing, editPicture } = profilePage;
    const { pictureStyle, canEdit, profileImageSize } = computed;

    const { setMemberPicture, setEditPicture } = handlers;
    const handlePhotoChange = forwardResizedImage(setMemberPicture);
    const handlePhotoUpload = forwardResizedImage(setEditPicture);
    const isSmallImage = profileImageSize === ProfileImageSize.Small;
    const isLargeImage = profileImageSize === ProfileImageSize.Large;

    const profilePhotoClasses = {
      [styles.profilePhoto]: true,
      [styles.profilePhotoSmall]: isSmallImage,
      [styles.profilePhotoLarge]: isLargeImage,
    };

    return (
      <AvatarInput
        className={classNames(profilePhotoClasses)}
        name={member.name}
        imageType={pictureStyle}
        url={(editPicture && editPicture.file) || member.picture}
        onClick={isEditing ? handlePhotoUpload : handlePhotoChange}
        canUpload={!isProfilePreview && canEdit}
        profileLayout={ProfileLayout.FullWidth}
        avatarSize={profileImageSize}
      />
    );
  };

  private readonly renderName = () => {
    const { member, site, profilePage, rolesMap, handlers, t, computed } =
      this.props;
    const { profileAlignment, showRoleLabel } = computed;
    const { isSocial } = site;
    const { isEditing, editName } = profilePage;
    const name = editName ?? member.name;

    const handleInputChange = forwardTargetValue(handlers.setEditName);
    const isProfileAlignCenter = profileAlignment === ProfileAlignment.Center;

    const nameBoxClasses = {
      [styles.nameBox]: true,
      [styles.nameBoxAlignCenter]: isProfileAlignCenter,
    };

    return (
      <div
        data-hook={DataHook.MemberName}
        className={classNames(nameBoxClasses)}
      >
        <div className={styles.nameWrapper}>
          {isEditing && (
            <div className={styles.nameBoxText}>
              <input
                data-hook={DataHook.MemberNameInput}
                ref={this.memberNameInputRef}
                className={styles.nameBoxInput}
                maxLength={50}
                size={50}
                defaultValue={name}
                onChange={handleInputChange}
              />
            </div>
          )}
          {!isEditing && (
            <MemberName
              className={styles.nameBoxText}
              tooltipClass={styles.tooltipWrapper}
              name={name}
            />
          )}
          <div className={styles.inputBorder} />
        </div>
        {isSocial && showRoleLabel && (
          <RoleIcons
            containerClassName={styles.roleIcons}
            iconClassName={styles.roleIcon}
            roles={member.roles}
            rolesMap={rolesMap}
            withNames={true}
            t={t}
          />
        )}
      </div>
    );
  };

  private readonly renderTitle = () => {
    const { member, profilePage, handlers, t, computed } = this.props;
    const { isEditing, editTitle } = profilePage;
    const { isDesignPreview } = computed;
    const title = editTitle ?? member.title;
    const handleTitleChange = forwardTargetValue(handlers.setEditTitle);
    const shouldShowTitlePreview = isDesignPreview && !isEditing && !title;

    return (
      <Fragment>
        {isEditing && (
          <div className={styles.titleWrapper}>
            <input
              className={classNames(styles.titleBoxInput, 'has-custom-focus')}
              size={titleMaxLength}
              maxLength={titleMaxLength}
              placeholder={t('profile-widget.title-placeholder')}
              defaultValue={title ?? ''}
              onChange={handleTitleChange}
            />
            <LimitCounter
              className={styles.limitCounter}
              length={title?.length ?? 0}
              limit={titleMaxLength}
            />
            <div className={styles.inputBorder} />
          </div>
        )}
        {!isEditing && title && (
          <div className={styles.titleWrapper}>
            <p className={classNames(styles.title)}>{title}</p>
          </div>
        )}
        {shouldShowTitlePreview && (
          <div className={styles.titleWrapper}>
            <p className={classNames(styles.title)}>
              {t('design-tab.title-preview')}
            </p>
          </div>
        )}
      </Fragment>
    );
  };

  private readonly renderFollowers = () => {
    const { member, handlers, computed } = this.props;
    const { followersInstalled, profileWidgetTexts } = computed;

    const handleFollowersBoxClick = absorbArguments(() => {
      handlers.navigateToViewedMemberFFPage(true);
    });

    const handleFollowingBoxClick = absorbArguments(() => {
      handlers.navigateToViewedMemberFFPage(false);
    });

    return (
      <div
        data-hook={DataHook.FollowersFollowing}
        className={styles.numbersBox}
      >
        <div
          data-hook={DataHook.Followers}
          className={styles.followersBoxContainer}
        >
          <Button
            className={classNames(styles.numbersBoxFollowers, {
              [styles.disabled]: !followersInstalled,
            })}
            onClick={handleFollowersBoxClick}
            isDisabled={!followersInstalled}
          >
            <span>{member.followerCount}</span>
            <div className={styles.numbersBoxText}>
              {profileWidgetTexts.followersCountersText}
            </div>
          </Button>
        </div>
        <div className={styles.divider} />
        <div data-hook={DataHook.Following}>
          <Button
            className={classNames(styles.numbersBoxFollowing, {
              [styles.disabled]: !followersInstalled,
            })}
            onClick={handleFollowingBoxClick}
            isDisabled={!followersInstalled}
          >
            <span>{member.followingCount}</span>
            <div className={styles.numbersBoxText}>
              {profileWidgetTexts.followingCountersText}
            </div>
          </Button>
        </div>
      </div>
    );
  };

  private readonly renderBlocked = () => {
    const { t } = this.props;
    return <Blocked className={styles.blocked} t={t} />;
  };

  private readonly renderBadges = () => {
    const { site, computed, handlers, i18n } = this.props;
    const { navigateToViewedMember } = handlers;
    const { badgesSettings, profileAlignment } = computed;
    const { isSocial } = site;
    const badgesAlignment = badgesAlignmentMap[profileAlignment];

    return (
      <div className={styles.badgesContainer}>
        <BadgeList
          {...badgesSettings}
          align={badgesAlignment}
          maxRows={1}
          onClickMore={isSocial ? navigateToViewedMember : undefined}
          onBadgeClick={isSocial ? navigateToViewedMember : undefined}
          i18n={i18n}
          httpClient={new HttpClient()}
        />
      </div>
    );
  };

  private readonly renderButtons = () => {
    const { site, profilePage, computed, handlers, t } = this.props;
    const {
      inCommunity,
      showAsBlocked,
      canEdit,
      isDesignPreview,
      isInProfilePage,
      showFollowButton,
      allowChat,
    } = computed;
    const { isProfilePreview } = profilePage;
    const { isSocial } = site;

    const { toggleIsEditingProfile, togglePublicProfilePreview } = handlers;
    const isInDesignPreviewAndSocial = isDesignPreview && isSocial;

    const shouldShowFollowButton =
      (showFollowButton && isSocial) || isInDesignPreviewAndSocial;
    const shouldShowChatButton = allowChat || isInDesignPreviewAndSocial;

    if (canEdit && !isProfilePreview && !isDesignPreview) {
      const {
        showViewPublicProfileCTA,
        showEditProfileCTA,
        isPublicMemberCandidateNoOne,
      } = computed;
      const isInSocialCommunity = inCommunity && isSocial;
      const handleEditButtonClick = absorbArguments(toggleIsEditingProfile);
      const handleViewPublicProfileButtonClick = () => {
        togglePublicProfilePreview();
      };
      const handleCommunityButtonClick = () => {
        handlers.executeRoleAction(RoleId.JOIN_COMMUNITY);
      };

      const shouldShowViewPublicProfileCTA =
        isInSocialCommunity && showViewPublicProfileCTA;
      const shouldShowJoinCommunityCTA =
        isSocial &&
        !inCommunity &&
        !showAsBlocked &&
        isInProfilePage &&
        !isPublicMemberCandidateNoOne;

      return (
        <div className={styles.buttonsBox}>
          {shouldShowJoinCommunityCTA && (
            <div
              data-hook={DataHook.JoinCommunityCTA}
              className={styles.mainButton}
            >
              {this.renderButton({
                className: styles.truncateText,
                title: t('MemberRoles.action_set.community'),
                isSecondary: true,
                onClick: handleCommunityButtonClick,
              })}
            </div>
          )}
          {isInSocialCommunity && showEditProfileCTA && (
            <div className={styles.mainButton}>
              {this.renderButton({
                dataHook: DataHook.EditProfileCTA,
                className: classNames(
                  styles.truncateText,
                  styles.editProfileButton,
                ),
                title: t('profile-widget.my-profile'),
                isSecondary: true,
                onClick: handleEditButtonClick,
              })}
            </div>
          )}
          {shouldShowViewPublicProfileCTA && (
            <div className={styles.mainButton}>
              {this.renderButton({
                dataHook: DataHook.ViewPubLicProfileCTA,
                className: classNames(
                  styles.truncateText,
                  styles.editProfileButton,
                ),
                title: t('profile-widget.view-public-profile'),
                isSecondary: true,
                onClick: handleViewPublicProfileButtonClick,
              })}
            </div>
          )}
          {this.renderActions()}
        </div>
      );
    }

    return (
      <div className={styles.buttonsBox}>
        {!showAsBlocked && (
          <Fragment>
            <div className={classNames(styles.mainButton, styles.chatButton)}>
              {shouldShowChatButton && this.renderChatButton()}
            </div>
            {shouldShowFollowButton && (
              <div
                className={classNames(styles.mainButton, styles.followButton)}
              >
                {this.renderFollowUnfollowButton()}
              </div>
            )}
          </Fragment>
        )}
        {this.renderActions()}
      </div>
    );
  };

  private readonly renderCoverImage = () => {
    const { computed, profilePage, host } = this.props;
    const {
      horizontalProfileCoverPhotoStyle,
      horizontalProfileCoverPhoto,
      profileWidgetHeight,
    } = computed;
    const { isCoverRepositionMode } = profilePage;

    if (!isCoverRepositionMode && horizontalProfileCoverPhoto?.src) {
      return (
        <WowImage
          data-hook={DataHook.HighQualityCover}
          className={styles.coverImage}
          src={horizontalProfileCoverPhoto?.src}
          resize={ImageResizeOptions.cover}
          focalPoint={{
            x: horizontalProfileCoverPhoto?.position.x ?? DEFAULT_FOCAL_POINT,
            y: horizontalProfileCoverPhoto?.position.y ?? DEFAULT_FOCAL_POINT,
          }}
          sourceHeight={profileWidgetHeight}
          sourceWidth={horizontalProfileCoverPhoto?.imageProps.rw}
          height={profileWidgetHeight}
          width={host?.dimensions?.width}
          shouldUseLQIP
        />
      );
    }

    return (
      <div
        data-hook={DataHook.HighQualityCover}
        className={classNames(
          styles.cover,
          !horizontalProfileCoverPhotoStyle && styles.coverColor,
        )}
        style={horizontalProfileCoverPhotoStyle || {}}
      />
    );
  };

  private readonly renderCoverButtons = () => {
    const { member, profilePage, computed, handlers, t, isRTL } = this.props;
    const { isCoverRepositionMode, isProfilePreview } = profilePage;
    const { canEdit, profileAlignment } = computed;
    const coverPhotoInputId = getElementId(
      this.props.compId,
      ElementId.CoverPhotoInput,
    );

    const { setEditCover, enterCoverRepositionMode } = handlers;

    const handleCoverUpload: ChangeEventHandler<HTMLInputElement> = (event) => {
      enterCoverRepositionMode();
      forwardResizedImage(setEditCover)(event);
    };

    const handleRepositionButtonClick = () => {
      enterCoverRepositionMode();
    };

    const isProfileAlignRight =
      profileAlignment === ProfileAlignment.Right && !isRTL;
    const isProfileAlignCenter =
      profileAlignment === ProfileAlignment.Center && isRTL;

    const coverPhotoContainerClasses = {
      [styles.coverPhotoContainer]: true,
      [styles.canEdit]: true,
      [styles.coverPhotoContainerAlignRight]: isProfileAlignRight,
      [styles.coverPhotoContainerAlignCenter]: isProfileAlignCenter,
    };

    return (
      <Fragment>
        {canEdit && !isCoverRepositionMode && !isProfilePreview && (
          <div className={classNames(coverPhotoContainerClasses)}>
            <input
              id={coverPhotoInputId}
              className={styles.uploadInput}
              type="file"
              accept={acceptableImageExtensions}
              aria-label={t('profile-widget.change-aria-label-photo')}
              onChange={handleCoverUpload}
            />
            <div
              data-hook={DataHook.ChangeCoverCTA}
              className={styles.coverPhotoChange}
            >
              <p className={styles.uploadText}>
                {t('profile-widget.change-photo')}
              </p>
              <CameraFill className={styles.coverPhotoUploadIcon} />
              <label
                htmlFor={coverPhotoInputId}
                className={styles.uploadLabel}
              />
            </div>
            {!!member.cover && (
              <RepositionButton
                dataHook={DataHook.ChangeCoverPositionCTA}
                className={styles.enterCoverRepositionButton}
                text={t('profile-widget.reposition')}
                onClick={handleRepositionButtonClick}
              />
            )}
          </div>
        )}
      </Fragment>
    );
  };

  private readonly renderChatButton = () => {
    const { profilePage, handlers, computed } = this.props;
    const { profileWidgetTexts } = computed;

    const handleChatButtonClick = absorbArguments(handlers.openChat);

    return (
      <Button
        className={styles.buttonSecondary}
        isDisabled={profilePage.isProfilePreview}
        onClick={handleChatButtonClick}
      >
        {profileWidgetTexts.messageButtonText}
      </Button>
    );
  };

  private readonly renderFollowUnfollowButton = () => {
    const { member, profilePage, handlers, computed } = this.props;
    const { followOrUnfollow } = handlers;
    const { profileWidgetTexts } = computed;

    const handleFollowButtonClick = absorbArguments(followOrUnfollow);

    return (
      <FollowButton
        dataHook={DataHook.FollowCTA}
        className={styles.buttonSecondary}
        followText={profileWidgetTexts.followButtonText}
        unfollowText={profileWidgetTexts.followingButtonText}
        type="text"
        isFollowing={member.isSubscribed}
        isDisabled={profilePage.isProfilePreview}
        onClick={handleFollowButtonClick}
      />
    );
  };

  private readonly renderActions = () => {
    const { member, isMobile, isRTL, computed, handlers } = this.props;
    const {
      additionalActions,
      rolesActions,
      isResponsiveEditor,
      profileAlignment,
    } = computed;
    const { isProfilePreview } = this.props.profilePage;
    const { uid } = member;
    const alignment =
      profileAlignment === ProfileAlignment.Right && !isRTL
        ? Position.Left
        : Position.Right;

    if (rolesActions.length === 0 && additionalActions.length === 0) {
      return;
    }

    const handleRoleAction = (roleId: RoleId) => {
      if (roleId === RoleId.CONTACT_PAGE) {
        const { metaSiteId } = this.props;
        return maybeOpenContactsWindow(metaSiteId, uid, isResponsiveEditor);
      }

      handlers.executeRoleAction(roleId);
    };

    return (
      <MemberMoreButton
        containerClass={styles.moreActions}
        additionalActions={additionalActions}
        rolesActions={rolesActions}
        alignment={alignment}
        isMobile={isMobile}
        isRtlLocale={isRTL}
        isDisabled={isProfilePreview}
        onAdditionalAction={handlers.executeAdditionalAction}
        onRoleAction={handleRoleAction}
      />
    );
  };

  private readonly renderButton = (props: RenderButtonProps) => {
    const className = props.className ?? defaultRenderButtonProps.className;
    const baseClassName = classNames(styles.mainButtonElement, className);
    const buttonClassName = props.isSecondary
      ? classNames(baseClassName, styles.buttonSecondary)
      : classNames(baseClassName, styles.buttonPrimary);

    const handleButtonClick: SubmitButtonClickHandler = (event) => {
      event.preventDefault();
      props.onClick(event);
    };

    return (
      <Button
        dataHook={props.dataHook}
        className={buttonClassName}
        isDisabled={props.isDisabled}
        onClick={handleButtonClick}
      >
        {props.title}
      </Button>
    );
  };
}

export default DesktopResponsiveWidget;
